import ApiService from "@/services/apiService";
import _ from "lodash";
import router from "../../router";
import moment from "moment";

const state = {
  user: {},
  globalSnackbarState: {
    isShowSnackbar: false,
    contents: []
  },
  globalState: {
    notify_global_nav: 0,
    notify_navs: {
      expenses: 0,
      holidayleaves: 0
    },
    my_incidence_counts: {
      expenses: 0,
      liquidation: 0,
      holiday: 0,
      leaves: 0
    },
    team_pending_approval_counts: {
      expenses: 0,
      liquidation: 0,
      holiday: 0,
      leaves: 0
    }
  }
};

const getters = {
  isAuthenticated(state) {
    return state.user && !_.isEmpty(state.user);
  },
  isAdmin(state) {
    return state.user && state.user.role === "admin";
  },
  isSubAdmin(state) {
    return state.user && state.user.role === "subadmin";
  },
  isStaff(state) {
    return state.user && state.user.role === "staff";
  },
  isBrand(state) {
    return state.user && state.user.role === "brand";
  },
  isManager(state) {
    return state.user && state.user.role === "manager";
  },
  isSPV(state) {
    return state.user && state.user.role === "spv";
  },
  isGPV(state) {
    return state.user && state.user.role === "gpv";
  },
  user(state) {
    return state.user;
  },
  globalState(state) {
    return state.globalState;
  },
  globalSnackbarState(state) {
    return state.globalSnackbarState;
  }
};

const safeRedirect = to => {
  if (router.currentRoute.path !== to) {
    return router.push(to);
  }
  return Promise.resolve();
};

const getInitialParams = () => {
  let currentDate = moment(new Date())
    .local()
    .format("YYYY-MM-DD");
  return {
    currentDate
  };
};

const generateGlobalState = data => {
  let currentDate = moment(new Date())
    .local()
    .format("YYYY-MM-DD");
  let global_state = {
    incidence_pendingapproval_counts: 0,
    pending_inactivepos: 0,
    pending_request_visitdays: 0,
    pending_new_pos: 0,
    pending_pos_change_approval: 0,
    pending_change_route: 0
  };
  const {
    incidence_pendingapproval_counts,
    pending_inactivepos,
    pending_request_visitdays,
    pending_new_pos,
    pending_pos_change_approval,
    pending_change_route
  } = data;
  if (incidence_pendingapproval_counts) {
    let counts = incidence_pendingapproval_counts;
    global_state = { ...global_state, ...counts };
  }
  if (pending_inactivepos) {
    global_state = { ...global_state, pending_inactivepos };
  }
  if (pending_request_visitdays) {
    global_state = { ...global_state, pending_request_visitdays };
  }
  if (pending_new_pos) {
    global_state = { ...global_state, pending_new_pos };
  }
  if (pending_change_route) {
    global_state = { ...global_state, pending_change_route };
  }
  if (pending_pos_change_approval) {
    global_state = { ...global_state, pending_pos_change_approval };
  }
  console.log("global_stateglobal_state - ", global_state);
  return global_state;
};

const generateSnackbarState = data => {
  let currentDate = moment(new Date())
    .local()
    .format("YYYY-MM-DD");
  let global_state = {
    isShowSnackbar: false,
    contents: []
  };
  if (data.workdayState) {
    const { isWorkableToday, workdaycountsfortoday } = data.workdayState;
    let current_day = moment(currentDate).day();
    if (
      isWorkableToday &&
      current_day !== 0 &&
      current_day !== 6 &&
      workdaycountsfortoday === 0
    ) {
      let currentTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      let startShouldTime = moment(new Date(currentDate + " 10:00:00")).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      if (startShouldTime < currentTime) {
        global_state.isShowSnackbar = true;
        global_state.contents.push("Recuerda iniciar tu jornada.");
      }
    }
  }
  if (data.my_team_liquidation_not_approved) {
    const {
      my_liquidations_not_approved,
      my_liquidations_pending_responsible_signature,
      team_liquidations_not_approved
    } = data.my_team_liquidation_not_approved;
    let is_notify_date_period = false;
    let current_date = moment(new Date()).format("DD");
    if (current_date <= 15 && current_date >= 2) {
      is_notify_date_period = true;
    }

    if (is_notify_date_period && my_liquidations_not_approved > 0) {
      global_state.isShowSnackbar = true;
      global_state.contents.push(
        "Tienes alguna liquidacion pendiente de aprobar. Revisa que este todo Correcto."
      );
    }
    if (
      is_notify_date_period &&
      my_liquidations_pending_responsible_signature > 0
    ) {
      global_state.isShowSnackbar = true;
      global_state.contents.push(
        "La liquidacion está pendiente de aprobar por tu responsable."
      );
    }
    if (is_notify_date_period && team_liquidations_not_approved > 0) {
      global_state.isShowSnackbar = true;
      global_state.contents.push(
        "Tienes liquidaciones de tu equipo pendientes de aprobar. Revisa que este todo Correcto."
      );
    }
  }
  return global_state;
};

const actions = {
  login(context, credentials) {
    context.commit("purge");
    return ApiService.post("auth/login", credentials) //email/password
      .then(data => {
        context.commit("set", data);
        let initialParams = getInitialParams();
        ApiService.post("getSnackbarState", initialParams).then(data => {
          let globalState = generateSnackbarState(data);
          console.log(
            "*************************************************sdfasdfsdfsdfsdfsdfsdf",
            globalState
          );
          context.commit("setSnackbarState", globalState);
        });
        alert("Recuerda usar Google Chrome y poner la vista ordenador !");
        if (data && data?.foundUser && data?.foundUser?.role == "brand") {
          const { landingPage } = data.foundUser;
          if (landingPage === "Portal fotos")
            return safeRedirect("/salesforce/portalphotos");
          if (landingPage === "Informes Marcas")
            return safeRedirect("/reporting/brand-reports");
          if (landingPage === "Mi ruta") return safeRedirect("/");
        }
        return safeRedirect("/");
      });
  },

  getGlobalState(context) {
    let initialParams = getInitialParams();
    return ApiService.post("getinitialstate", initialParams).then(data => {
      let globalState = generateGlobalState(data);
      context.commit("setGlobalState", globalState);
    });
  },

  check(context) {
    return new Promise((resolve, reject) => {
      ApiService.get("auth/whoami")
        .then(data => {
          context.commit("set", data);
          resolve(data.user);
        })
        .catch(() => {
          context.commit("purge");
          reject();
        });
    });
  },

  logout(context) {
    context.commit("purge");
    return safeRedirect("/auth/login");
  },

  onForbidden() {
    //redirect home
    return safeRedirect("/");
  },

  hideglobalsnackbar(context) {
    context.commit("hideglobalsnackbar");
  }
};

const mutations = {
  hideglobalsnackbar(state) {
    state.globalSnackbarState.isShowSnackbar = false;
  },
  setGlobalState(state, data) {
    state.globalState = Object.assign({}, state.globalState, data);
  },
  setSnackbarState(state, data) {
    state.globalSnackbarState = Object.assign(
      {},
      state.globalSnackbarState,
      data
    );
  },
  set(state, { user, token }) {
    if (token) {
      ApiService.saveToken(token);
    }
    state.user = user;
  },
  purge(state) {
    ApiService.destroyToken();
    state.user = {};
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
